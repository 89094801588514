export type Reason = {
  title: string;
  description: string;
  icon: string;
};

const reasons: Reason[] = [
  {
    title: 'Better experience for travelers',
    description:
      'Understand your airport operations pain points for your customers and take action to improve their experience.',
    icon: 'experience',
  },
  {
    title: 'Greater awareness and control',
    description:
      'Monitor your real-time airport operations data all in one place and recieve active alerts based on configurable thresholds.',
    icon: 'control',
  },
  {
    title: 'Higher adaptability for changes',
    description:
      'With a higher visibility into your airport operations, you can become proactive to continual changes.',
    icon: 'adaptability',
  },
  {
    title: 'Greater cost efficiencies',
    description:
      'Assess your operations performance and activity to see opportunity for cost efficiency improvement.',
    icon: 'efficiencies',
  },
];

export { reasons };
