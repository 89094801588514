import React from 'react';

interface Props {
  title: string;
  children?: React.ReactNode;
}

const SectionIntro: React.FC<Props> = ({ title, children }: Props) => {
  return (
    <>
      <h2 className="text-3xl xs:text-4xl font-bold mb-4">{title}</h2>
      {children && (
        <p className="text-lg xs:text-xl md:text-2xl font-light text-gray max-w-3xl">
          {children}
        </p>
      )}
    </>
  );
};

export { SectionIntro };
