export interface Track {
  type: string;
  event: string;
  data: any;
}

function track(args: Track): void {
  if (typeof window !== 'undefined') {
    if (window.gtag) {
      window.gtag(args.type, args.event, args.data);
    }
  }
}

export { track };

declare global {
  interface Window {
    gtag: (type: string, event: string, data: any) => void;
  }
}
