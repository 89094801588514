import * as React from 'react';

import { Layout } from '../components/Layout';
import { Masthead } from '../components/Masthead';
import { Features } from '../components/Features';
import { Details } from '../components/Details';
import { Reasoning } from '../components/Reasoning';
import { Partners } from '../components/Partners';
import { Contact } from '../components/Contact';
import { SEO } from '../components/SEO';
import { Team } from '../components/Team';

const IndexPage = () => {
  const featuresRef = React.useRef<null | HTMLDivElement>(null);
  const reasoningRef = React.useRef<null | HTMLDivElement>(null);
  const contactRef = React.useRef<null | HTMLDivElement>(null);

  const scroll = (title: string) => {
    const options: { [key: string]: string } = {
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    };

    switch (title) {
      case 'Features': {
        featuresRef?.current?.scrollIntoView(options);
        return;
      }
      case 'Why EASE?': {
        reasoningRef?.current?.scrollIntoView(options);
        return;
      }
      case 'Contact Us': {
        contactRef?.current?.scrollIntoView(options);
        return;
      }
      default: {
        return;
      }
    }
  };

  return (
    <Layout>
      <Masthead onClick={scroll} />
      <Features ref={featuresRef} />
      <Details />
      <Reasoning ref={reasoningRef} />
      <Team />
      <Partners />
      <Contact ref={contactRef} />
    </Layout>
  );
};

export function Head() {
  return <SEO />;
}

export default IndexPage;
