import React from 'react';

interface Props {
  children: React.ReactNode;
  to?: string;
  type?: string;
  variant?: 'secondary';
  onClick?: () => void;
}

const Button: React.FC<Props> = ({ to, children, type, variant }: Props) => {
  const base =
    'cursor-pointer inline-flex items-center px-6 py-3 text-base font-medium rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition-colors duration-150 ease-in-out';

  const VARIANTS: {
    [key: string]: string;
  } = {
    primary:
      'border border-primary hover:border-white text-gray-900 bg-primary hover:bg-white',
    secondary:
      'border border-gray-900 hover:border-gray-700 text-white bg-gray-900 hover:bg-gray-700',
  };

  if (type === 'submit') {
    return (
      <button className={`${base} ${VARIANTS[variant || 'primary']}`}>
        {children}
      </button>
    );
  }

  return (
    <a href={to} className={`${base} ${VARIANTS[variant || 'primary']}`}>
      {children}
    </a>
  );
};

export { Button };
