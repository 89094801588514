import React, { useState, forwardRef } from 'react';

import { features } from '../config/features';
import { Cell } from './Cell';
import { Icon } from './Icon';
import { Row } from './Row';
import { SectionIntro } from './SectionIntro';
import { TextLabel } from './TextLabel';

import type { Feature } from '../config/features';
import { StaticImage } from 'gatsby-plugin-image';

interface Props {
  ref?: React.Ref<HTMLDivElement>;
}

const Features: React.FC<Props> = forwardRef(
  (_props: Props, ref: React.Ref<HTMLDivElement>) => {
    return (
      <div ref={ref}>
        <Row className="py-8 xs:py-10 md:py-24 bg-white">
          <Cell>
            <div className="mb-6 xs:mb-10">
              <Intro />
            </div>
            <DesktopList />
            <MobileList />
          </Cell>
        </Row>
      </div>
    );
  }
);

function Intro() {
  return (
    <SectionIntro title="Meet your data where it is.">
      Whether you're managing sales data in a spreadsheet or receiving
      power-outage emails,{' '}
      <strong className="font-bold">
        <em className="italic">EASE</em>
      </strong>{' '}
      becomes your Data Warehouse, automatically pulling data into a single view
      of comprehensive tiles.
    </SectionIntro>
  );
}

function DesktopList() {
  const [count, setCount] = useState(0);
  const DEFAULT_FEATURE = features[count].title;
  const [active, setActive] = useState<string>(DEFAULT_FEATURE);

  const handleMouseEnter = (title: string) => (_event: React.MouseEvent) => {
    setActive(title);
    setCount(features.findIndex((feature) => feature.title === title));
  };

  function isActive(title: string) {
    return active === title;
  }

  return (
    <div className="md:grid grid-cols-2 gap-x-6 relative hidden">
      <ol>
        {features.map((feature: Feature, idx: number) => (
          <li key={idx} className="mb-2">
            <a
              href="#0"
              className={`block p-4 border border-gray-100 border-opacity-0 transition-shadow duration-200 ${
                isActive(feature.title)
                  ? 'rounded-md border-opacity-75 shadow-lg bg-white'
                  : ''
              }`}
              onMouseEnter={handleMouseEnter(feature.title)}
            >
              <div className="flex">
                <div
                  className={`mr-3 transition-colors duration-200 ${
                    isActive(feature.title) ? 'text-primary' : 'text-gray-400'
                  }`}
                >
                  {feature.icon && <Icon name={feature.icon} />}
                </div>
                <div>
                  <TextLabel>{feature.title}</TextLabel>
                  <span
                    className={`text-sm text-gray-400 ${
                      isActive(feature.title) ? 'visible' : 'hidden'
                    }`}
                  >
                    {feature.description}
                  </span>
                </div>
              </div>
            </a>
          </li>
        ))}
      </ol>
      <div className="hidden md:block relative -mr-72">
        <Image title="Alerts" isActive={isActive}>
          <AlertsImage />
        </Image>
        <Image title="Flights" isActive={isActive}>
          <FlightsImage />
        </Image>
        <Image title="Security" isActive={isActive}>
          <SecurityImage />
        </Image>
        <Image title="Weather" isActive={isActive}>
          <WeatherImage />
        </Image>
        <Image title="Scans" isActive={isActive}>
          <ScansImage />
        </Image>
        <Image title="Parking" isActive={isActive}>
          <ParkingImage />
        </Image>
        <Image title="Traffic" isActive={isActive}>
          <TrafficImage />
        </Image>
        <Image title="Facility" isActive={isActive}>
          <FacilityImage />
        </Image>
        <Image title="Airfield" isActive={isActive}>
          <AirfieldImage />
        </Image>
        <Image title="Sales" isActive={isActive}>
          <SalesImage />
        </Image>
        <Image title="Custom" isActive={isActive}>
          <CustomImage />
        </Image>
      </div>
    </div>
  );
}

function MobileList() {
  return (
    <div className="block md:hidden">
      {features.map((feature: Feature, idx: number) => (
        <div className="mb-10" key={idx}>
          <div className="mb-6">
            <feature.image />
          </div>
          <div className="mb-2 transition-colors duration-200 text-gray-400">
            {feature.icon && <Icon name={feature.icon} />}
          </div>
          <div>
            <TextLabel>{feature.title}</TextLabel>
            <span className="text-sm text-gray-400">{feature.description}</span>
          </div>
        </div>
      ))}
    </div>
  );
}

type ImageProps = {
  title: string;
  isActive: (title: string) => boolean;
  children: React.ReactNode;
};

function Image(props: ImageProps) {
  return (
    <div
      className={`transition ease-in-out duration-300 ${
        props.isActive(props.title) ? 'opacity-100' : 'opacity-0'
      }`}
    >
      <div className="absolute right-0 top-0">{props.children}</div>
    </div>
  );
}

function AlertsImage() {
  return (
    <StaticImage
      src="../images/img-alerts.png"
      alt="Alerts Feature"
      objectFit="cover"
      width={746}
      height={683}
      quality={100}
    />
  );
}

function FlightsImage() {
  return (
    <StaticImage
      src="../images/img-flights.png"
      alt="Flights Feature"
      objectFit="cover"
      width={766}
      height={644}
      quality={100}
    />
  );
}

function SecurityImage() {
  return (
    <StaticImage
      src="../images/img-security.jpg"
      alt="Security Feature"
      objectFit="cover"
      width={746}
      height={643}
      quality={100}
    />
  );
}

function WeatherImage() {
  return (
    <StaticImage
      src="../images/img-weather.png"
      alt="Weather Feature"
      objectFit="cover"
      width={746}
      height={643}
      quality={100}
    />
  );
}

function ScansImage() {
  return (
    <StaticImage
      src="../images/img-scans.png"
      alt="Scans Feature"
      objectFit="cover"
      width={766}
      height={643}
      quality={100}
    />
  );
}

function ParkingImage() {
  return (
    <StaticImage
      src="../images/img-parking.jpg"
      alt="Parking Feature"
      objectFit="cover"
      width={746}
      height={643}
      quality={100}
    />
  );
}

function TrafficImage() {
  return (
    <StaticImage
      src="../images/img-traffic.png"
      alt="Traffic Feature"
      objectFit="cover"
      width={747}
      height={643}
      quality={100}
    />
  );
}

function FacilityImage() {
  return (
    <StaticImage
      src="../images/img-facility.png"
      alt="Facility Feature"
      objectFit="cover"
      width={746}
      height={643}
      quality={100}
    />
  );
}

function AirfieldImage() {
  return (
    <StaticImage
      src="../images/img-airfield.png"
      alt="Airfield Feature"
      objectFit="cover"
      width={747}
      height={643}
      quality={100}
    />
  );
}

function SalesImage() {
  return (
    <StaticImage
      src="../images/img-sales.png"
      alt="Sales Feature"
      objectFit="cover"
      width={766}
      height={643}
      quality={100}
    />
  );
}

function CustomImage() {
  return (
    <StaticImage
      src="../images/img-custom.png"
      alt="Custom Feature"
      objectFit="cover"
      width={746}
      height={643}
      quality={100}
    />
  );
}

export {
  Features,
  AlertsImage,
  FlightsImage,
  SecurityImage,
  WeatherImage,
  ScansImage,
  ParkingImage,
  TrafficImage,
  FacilityImage,
  AirfieldImage,
  SalesImage,
  CustomImage,
};
