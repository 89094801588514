import { track } from './track';

type Event = {
  [key: string]: any;
};

const events: Event = {
  submitContactForm: () =>
    track({
      type: 'event',
      event: 'submit',
      data: { action: 'Submit Contact Form' },
    }),
};

export { events };
