import React from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const TextLabel: React.FC<Props> = ({ children, className }: Props) => {
  return (
    <span
      className={`block font-bold uppercase tracking-widest text-lg ${className}`}
    >
      {children}
    </span>
  );
};

export { TextLabel };
