import React, { forwardRef } from 'react';

import { events } from '../utils/events';
import { Button } from './Button';
import { Cell } from './Cell';
import { Icon } from './Icon';
import { Row } from './Row';

interface Props {
  ref?: React.Ref<HTMLDivElement>;
}

const Contact: React.FC<Props> = forwardRef(
  (_props: Props, ref: React.Ref<HTMLDivElement>) => {
    return (
      <div ref={ref}>
        <Row id="contact" className="py-6 xs:py-10 md:py-24">
          <Cell>
            <div className="xl:grid grid-cols-2 gap-x-8">
              <div className="mb-8 xl:mb-0">
                <h2 className="text-2xl xs:text-3xl font-bold mb-2">
                  Not ready for a demo?
                </h2>
                <p className="text-gray-500 mb-6">
                  Just send us a message with any questions you have.
                </p>
                <div className="flex items-center gap-x-2">
                  <div className="text-primary">
                    <Icon name="contact" />
                  </div>
                  <p>info@ease.aero</p>
                </div>
              </div>
              <Form />
            </div>
          </Cell>
        </Row>
      </div>
    );
  }
);

function Form() {
  return (
    <form
      name="contact"
      method="POST"
      data-netlify="true"
      action="/success"
      onSubmit={events.submitContactForm}
    >
      <input type="hidden" name="form-name" value="contact" />
      <div className="mb-8">
        <label
          htmlFor="name"
          className="block text-sm font-sm text-gray-700 mb-2"
        >
          Full Name
        </label>
        <input
          type="name"
          name="name"
          id="name"
          className="px-3 py-2 shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border border-gray-100 rounded-md"
          placeholder="John Appleseed"
          required
        />
      </div>
      <div className="md:grid grid-cols-2 gap-x-4 mb-8">
        <div className="mb-8 md:mb-0">
          <label
            htmlFor="email"
            className="block text-sm font-sm text-gray-700 mb-2"
          >
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            className="px-3 py-2 shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border border-gray-100 rounded-md"
            placeholder="you@example.com"
            required
          />
        </div>
        <div>
          <label
            htmlFor="company"
            className="block text-sm font-sm text-gray-700 mb-2"
          >
            Company
          </label>
          <input
            type="company"
            name="company"
            id="company"
            className="px-3 py-2 shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border border-gray-100 rounded-md"
            placeholder="Your Company"
            required
          />
        </div>
      </div>
      <div className="mb-8">
        <label
          htmlFor="message"
          className="block text-sm font-sm text-gray-700 mb-2"
        >
          Message
        </label>
        <textarea
          rows={4}
          name="message"
          id="message"
          className="px-3 py-2 shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border border-gray-100 rounded-md"
          placeholder="Type your message here..."
          required
        />
      </div>
      <Button type="submit" variant="secondary">
        Send Message
      </Button>
    </form>
  );
}

export { Contact };
