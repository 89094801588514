import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import { links } from '../config/links';
import { nav } from '../config/nav';
import { Logo } from './Logo';

import type { Props as MastheadProps } from './Masthead';
import type { Nav as Link } from '../config/nav';

const Nav: React.FC<MastheadProps> = (props: MastheadProps) => {
  return (
    <div className="md:grid grid-cols-2 mb-6 xs:mb-10 md:mb-20 items-center">
      <h1 className="flex justify-center xs:block text-3xl font-bold mb-8 md:mb-0">
        <a className="block" href="/" aria-label="EASE">
          <Logo />
        </a>
      </h1>
      <nav>
        <ul className="flex justify-between items-center text-xs xs:text-base">
          {nav.map((link: Link, idx) => (
            <li
              key={idx}
              className="font-bold"
              onClick={(event: React.MouseEvent) => {
                event.preventDefault();
                props.onClick && props.onClick(link.title);
              }}
            >
              <a className="hover:underline" href={link.to}>
                {link.title}
              </a>
            </li>
          ))}
          <li className="hidden xl:block">
            <OutboundLink
              href={links.demo}
              className="cursor-pointer inline-flex items-center px-6 py-3 text-base font-medium rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition-colors duration-150 ease-in-out border border-primary hover:border-white text-gray-900 bg-primary hover:bg-white"
            >
              Schedule Demo
            </OutboundLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export { Nav };
