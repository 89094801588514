export type Nav = {
  to: string;
  title: string;
};

const nav: Nav[] = [
  { to: '#features', title: 'Features' },
  { to: '#reasoning', title: 'Why EASE?' },
  { to: '#contact', title: 'Contact Us' },
];

export { nav };
