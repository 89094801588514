import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import { links } from '../config/links';
import { Cell } from './Cell';
import { Row } from './Row';
import partners from '../images/bg-partners.jpg';

const Partners: React.FC = () => {
  return (
    <div
      style={{ backgroundImage: `url(${partners})` }}
      className="bg-no-repeat bg-cover bg-gray-800"
    >
      <Row className="py-6 xs:py-10 md:py-24 text-white">
        <Cell>
          <div className="mb-10 md:mb-40">
            <Testimonial />
          </div>
          <Partner />
        </Cell>
      </Row>
    </div>
  );
};

function Testimonial() {
  return (
    <div className="md:flex gap-x-8 mt-20">
      <div className="text-gray-500 relative -top-5 xs:-top-10 md:-top-20">
        <svg
          width="144"
          height="117"
          viewBox="0 0 144 117"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M41.485 2C17.753 18.753 1 46.208 1 76.455C1 101.119 15.891 115.545 33.109 115.545C49.396 115.545 61.495 102.515 61.495 87.158C61.495 71.802 50.792 60.634 36.832 60.634C34.04 60.634 30.317 61.099 29.386 61.564C31.713 45.743 46.604 27.129 61.496 17.822L41.485 2ZM121.525 2C98.257 18.753 81.505 46.208 81.505 76.455C81.505 101.119 96.396 115.545 113.614 115.545C129.436 115.545 142 102.515 142 87.158C142 71.802 130.832 60.634 116.871 60.634C114.079 60.634 110.822 61.099 109.891 61.564C112.218 45.743 126.644 27.129 141.535 17.822L121.525 2Z"
            stroke="currentColor"
            strokeWidth="2"
          />
        </svg>
      </div>
      <div className="flex-1">
        <div className="text-xl xs:text-2xl font-light md:max-w-4xl leading-normal mb-6 md:mb-10">
          <p className="mb-4">
            EASE is designed to operate as it sounds. As an end user, decision
            maker, and purchaser of complex software products, I was tired of
            vendors overpromising and underdelivering &mdash; the inevitable
            haggling on software activation, acceptance, and in some cases,
            modified customization. Of course, all was usually accompanied by an
            equally high price and some level of buyer's remorse.
          </p>
          <p className="mb-4">
            EASE and the team behind it understand what a challenging enterprise
            an airport can be. We set out to reduce the complexity through
            visual awareness and only to alert on situational exceptions. Reduce
            the number of peripheral monitors. Reduce the staff inefficiencies
            in responding to emergent issues in the blind. Reduce the
            anxiety-inducing chaos associated with the unknown knowns.
          </p>
          <p>We can do better, and we can do it better with EASE.</p>
        </div>
        <div className="md:flex justify-end gap-x-4">
          <div className="mb-4 md:mb-0">
            <p className="text-sm xs:text-base font-bold">
              Brian Cobb, Chief Innovation Officer
            </p>
            <p className="text-sm md:text-base">
              Cincinnati/Northern Kentucky International Airport
            </p>
          </div>
          <div className="flex items-center gap-x-4">
            <StaticImage
              src="../images/img-brian-cobb.jpg"
              alt="Image of Brian Cobb"
              width={48}
              height={48}
              imgClassName="rounded-full h-36 w-36"
            />
            <StaticImage
              src="../images/img-cvg.png"
              alt="CVG Logo"
              width={87}
              height={32}
              objectFit="contain"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function Partner() {
  return (
    <div className="bg-gray-900 rounded-md shadow-xl p-6 xs:p-8 md:p-12 text-white text-center md:text-left">
      <div className="md:flex items-center">
        <div className="mb-6 md:mb-0">
          <h2 className="text-2xl xs:text-3xl md:text-4xl font-bold mb-4">
            Become an EASE Innovation Partner{' '}
          </h2>
          <p className="text-sm max-w-xl">
            EASE is shaped and improved by the collective knowledge and growth
            across all Partner Airports while also providing the ability to view
            custom data-sets that match your airport’s specific needs.
          </p>
        </div>
        <div className="ml-auto">
          <OutboundLink
            href={links.demo}
            className="cursor-pointer inline-flex items-center px-6 py-3 text-base font-medium rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition-colors duration-150 ease-in-out border border-primary hover:border-white text-gray-900 bg-primary hover:bg-white"
          >
            Schedule Demo
          </OutboundLink>
        </div>
      </div>
    </div>
  );
}

export { Partners };
