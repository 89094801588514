import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import { Cell } from './Cell';
import { Row } from './Row';
import { SectionIntro } from './SectionIntro';

const Details: React.FC = () => {
  return (
    <Row className="py-6 xs:py-10 md:py-24 bg-gradient-to-b from-gray-700 to-gray-800 text-white">
      <Cell>
        <div className="mb-6 xs:mb-10 md:mb-20">
          <Intro />
        </div>
        <div className="md:flex gap-x-10 mb-6 xs:mb-10 md:mb-20">
          <div>
            <StaticImage
              src="../images/img-byot.png"
              alt="Screenshot of tile customization in EASE"
              quality={100}
            />
          </div>
          <div>
            <StaticImage
              src="../images/img-layout.png"
              alt="Screenshot of a customized layout in EASE"
            />
          </div>
        </div>
        <div className="md:flex gap-x-10">
          <div className="mb-6 xs:mb-10 md:mb-0 md:max-w-3xl">
            <h2 className="text-3xl xs:text-4xl font-bold mb-4">
              Improve insights through<span>&nbsp;</span>Reports.
            </h2>
            <p className="text-lg xs:text-xl md:text-2xl font-light text-gray">
              Run reports and compare different data sets to view trends over a
              configurable range of time.{' '}
            </p>
          </div>
          <div>
            <StaticImage
              src="../images/img-reports.png"
              alt="Screenshot of a Report in EASE"
              quality={100}
            />
          </div>
        </div>
      </Cell>
    </Row>
  );
};

function Intro() {
  return (
    <SectionIntro title="View data that is relevant to you.">
      No matter your role, EASE is for you. Customize your layout to show the
      information you want to see, and how you want to see it.
    </SectionIntro>
  );
}

export { Details };
