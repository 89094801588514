import React from 'react';
import {
  AlertsImage,
  FlightsImage,
  SecurityImage,
  WeatherImage,
  ScansImage,
  ParkingImage,
  TrafficImage,
  FacilityImage,
  AirfieldImage,
  SalesImage,
  CustomImage,
} from '../components/Features';

export type Feature = {
  title: string;
  description: string;
  icon?: string;
  image: React.FC;
};

const features: Feature[] = [
  {
    title: 'Alerts',
    description: 'Receive active alerts based on configurable thresholds.',
    icon: 'alerts',
    image: AlertsImage,
  },
  {
    title: 'Flights',
    description:
      'View arrivals, departures, and a flight summary for all flights.',
    icon: 'flights',
    image: FlightsImage,
  },
  {
    title: 'Security',
    description: 'View real-time wait data, trends and receive active alerts.',
    icon: 'security',
    image: SecurityImage,
  },
  {
    title: 'Weather',
    description: 'View current weather conditions and FAA delay information.',
    icon: 'weather',
    image: WeatherImage,
  },
  {
    title: 'Scans',
    description:
      'View boarding-pass scans, passenger demographics, and capacity data.',
    icon: 'scans',
    image: ScansImage,
  },
  {
    title: 'Parking',
    description: 'View real-time parking data and trends.',
    icon: 'parking',
    image: ParkingImage,
  },
  {
    title: 'Traffic',
    description:
      'View traffic conditions, reported disruptions, and recieve real-time alerts.',
    icon: 'traffic',
    image: TrafficImage,
  },
  {
    title: 'Facility',
    description:
      'View facility conditions (i.e. power outages) and recieve real-time alerts.',
    icon: 'facility',
    image: FacilityImage,
  },
  {
    title: 'Airfield',
    description:
      'View Notice to Air Missions data from the FAA and airside operations team.',
    icon: 'airfield',
    image: AirfieldImage,
  },
  {
    title: 'Sales',
    description:
      'View sales data (concessions, parking, etc.) and any cargo revenue.',
    icon: 'sales',
    image: SalesImage,
  },
  {
    title: 'Custom',
    description:
      'Build a custom tile to visualize data specific to your environment.',
    icon: 'custom',
    image: CustomImage,
  },
];

export { features };
