import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import { links } from '../config/links';
import { Cell } from './Cell';
import { Row } from './Row';
import { Nav } from './Nav';

export interface Props {
  onClick?: (title: string) => void;
}

const Masthead: React.FC<Props> = (props: Props) => {
  return (
    <Row className="py-8 xs:py-10 bg-masthead bg-gradient-to-r from-black to-gray-700 xl:min-h-[800px] text-white">
      <Cell>
        <Nav {...props} />
        <Intro />
      </Cell>
      <div className="animate-fade-in opacity-0">
        <div className="absolute right-0 bottom-10 opacity-10 xl:opacity-100">
          <StaticImage
            src="../images/bg-masthead.png"
            alt="Screenshots of the EASE interface"
            width={831}
            height={585}
            quality={100}
          />
        </div>
      </div>
    </Row>
  );
};

function Intro() {
  return (
    <div className="md:max-w-lg md:pb-20">
      <h2 className="text-4xl md:text-6xl leading-[1.2] md:leading-[1.1] font-bold mb-4 xs:mb-6">
        Run your airport with EASE.
      </h2>
      <p className="text-lg xs:text-xl md:text-2xl leading-normal md:leading-tight font-light mb-6 xs:mb-8 md:max-w-xl xl:max-w-lg">
        A data-integration platform for real-time operational responses. Collect
        key data insights, predict customer behavior, and direct confidently.
      </p>
      <OutboundLink
        href={links.demo}
        className="cursor-pointer inline-flex items-center px-6 py-3 text-base font-medium rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition-colors duration-150 ease-in-out border border-primary hover:border-white text-gray-900 bg-primary hover:bg-white"
      >
        Schedule Demo
      </OutboundLink>
    </div>
  );
}

export { Masthead };
